import React, { memo } from 'react'
import './spinner.scss'

const Spinner = props => {
    const {
        appSpinner = false,
        isLoading = false,
    } = props

    if (!isLoading) return null
    let className = 'loading_container'
    if (appSpinner) className += ' app_spinner'

    return (
        <div className={className}>
            <div className='circle' />
        </div>
    )
}

export default memo(Spinner)