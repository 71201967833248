import { createContext, useCallback, useRef } from "react"
import { Processing } from "components"

const initialState = {
    showProcess: () => { },
    hideProcess: () => { },
    errorProcess: () => { }
}

const AppProcessingContext = createContext(initialState)

export const AppProcessingContextProvider = ({ children }) => {

    const processingRef = useRef({})

    const showProcess = useCallback(() => {
        processingRef.current.showProcess()
    }, [])

    const hideProcess = useCallback(() => {
        processingRef.current.hideProcess()
    }, [])

    const stopProcess = useCallback(() => {
        processingRef.current.stopProcess()
    }, [])

    const errorProcess = useCallback(() => {
        processingRef.current.errorProcess()
    }, [])

    return (
        <AppProcessingContext.Provider value={{ showProcess, hideProcess, stopProcess, errorProcess }}>
            {children}
            <Processing ref={processingRef} />
        </AppProcessingContext.Provider >
    )
}

export default AppProcessingContext
