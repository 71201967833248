import axios from "axios"

const signup = async (userData) => {
    const response = await axios.post('/user/signup', userData, { appSpinner: true })

    if (response.data && !response.data.error) {
        global.setSession(response.data.user, response.data.token)
    } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    return response.data
}

const login = async (userData) => {
    const response = await axios.post('/user/login', userData, { appSpinner: true })
    if (response.data && !response.data.error) {
        global.setSession(response.data.user, response.data.token)
    } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }

    return response.data
}

const logout = async () => {
    const response = await axios.post('/user/logout', {}, { appSpinner: true })
    return response.data
}

const authFunctions = { signup, login, logout }

export default authFunctions