
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { Button, Grid, Text, ComboBox } from 'components'


import './side_menu.scss'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { childRoutes, routes } from 'routing/routes'

const statusClass = {
    0: { statusName: 'Not-applicable' },
    1: { statusName: 'Active' },
    2: { statusName: 'Compliant' },
    3: { statusName: 'in_progress' },
    4: { statusName: 'compromised' },
}

const SideMenu = (props) => {

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const { setStandardAttribute, standardAttribute } = props
    const [standardsOptions, setStandardsOptions] = useState({ 1: 'ISO 27001' })
    const [attributeOptions, setAttributeOptions] = useState({ 0: 'Domain' })
    const [attributeValues, setAttributeValues] = useState([])

    const getStandardsOptions = async () => {
        try {
            const response = await axios.get('/standards')

            if (response.data) {
                setStandardsOptions(response.data.response)
            }
        } catch (error) {
            console.error('GET_STANDARD_OPTIONS_ERROR', error)
        }
    }

    const getAttributeOptions = async (standardId = 1) => {
        try {
            const response = await axios.get(`/standards/properties/${standardId}`)
            if (response.data) {
                setAttributeOptions(response.data.response)
                setStandardAttribute(standardAttribute => ({ ...standardAttribute, attribute: 0 }))
                getAttributeValues(0, standardId)
            }
        } catch (error) {
            console.error('GET_ATTRIBUTE_OPTIONS_ERROR', error)
        }
    }

    const getAttributeValues = async (attributeId = 0, standardId = 1) => {
        try {
            const response = await axios.get(`/standards/properties/${attributeId}/values${Number(attributeId) === 0 ? `?standardId=${standardId}` : ''}`)
            if (response.data) {
                setAttributeValues(response.data.response)
            }
        } catch (error) {
            console.error('GET_ATTRIBUTE_VALUES_ERROR', error)
        }
    }

    const handleValue = (value, name) => {
        const isControlOpen = matchPath({ path: '/cybersecurity/' + childRoutes.cybersecurityControlContent.path }, pathname)
        if (isControlOpen) {
            navigate(routes.cybersecurity.path)
        }
        setStandardAttribute(standardAttribute => ({ ...standardAttribute, [name]: value }))
        if (name === 'standard') {
            getAttributeOptions(value)
        }
        if (name === 'attribute') {
            getAttributeValues(value, standardAttribute.standard)
        }
    }

    useEffect(() => {
        getStandardsOptions()
        getAttributeOptions()
        getAttributeValues()
        // eslint-disable-next-line
    }, [])

    return (
        <Grid className="cyber_security__side_menu" maxWidth="300px" itemsX="center" contentY="start" gap="24px" padding="24px 0px">
            <ComboBox
                label="Standard"
                options={standardsOptions}
                onChange={(event) => { handleValue(event, "standard") }}
                value={standardAttribute.standard}
                w100
                maxWidth="200px"
            />
            <Grid columns="auto auto" gap="12px" padding="0px 10px">
                <Button>Certificaciones</Button>
                <Button variant="outlined">Reportes</Button>
            </Grid>
            <ComboBox
                label="Attribute"
                options={attributeOptions}
                onChange={(event) => { handleValue(event, "attribute") }}
                value={standardAttribute.attribute}
                w100
                maxWidth="200px"
            />
            <Grid w100 className="attribute_values__container">
                {
                    attributeValues.map((value, i) => (
                        <Grid
                            className='attribute_values__option'
                            padding="14px"
                            columns="1fr auto"
                            itemsY="center"
                            gap="10px"
                            maxWidth="275px"
                            key={i}
                            onClick={() => { handleValue(value.name, "controlName"); handleValue(value.id, 'controlId') }}>
                            <Text align="center">{value.name}</Text>
                            <span className={`${statusClass[value.statusId]?.statusName}`} />
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default SideMenu
