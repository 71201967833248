import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import reactFastCompare from 'react-fast-compare'
import AccordionLayout from '../Accordion/AccordionLayout'
import useAccordion from '../hooks/useAccordion'

import './accordion_group.scss'

const AccordionGroupTab = memo((props) => {
    /*------------------------------------PROPS--------------------------------*/
    let { className } = props
    const { title, toggleElement, accordionIdx, isOpen, children, toggleAccordion } = props

    /*------------------------------------HOOKS--------------------------------*/
    const { contentRef, getContentSize } = useAccordion()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const handleToggleAccordion = useCallback(() => {
        toggleAccordion(accordionIdx)
    }, [accordionIdx, toggleAccordion])

    /*------------------------------------RENDER--------------------------------*/
    const contentStyles = { height: isOpen ? `${getContentSize()}px` : 0 }
    className = className ? `${className} accordion` : 'accordion'

    return <AccordionLayout {...{
        children,
        className,
        contentRef,
        contentStyles,
        isOpen,
        title,
        toggleAccordion: handleToggleAccordion,
        toggleElement
    }} />
}, reactFastCompare)

const AccordionGroup = (props, ref) => {
    /*------------------------------------PROPS--------------------------------*/
    let { className } = props
    const { accordions, onToggle } = props

    /*------------------------------------STATE--------------------------------*/
    const [openAccordion, setOpenAccordion] = useState(0)

    /*------------------------------------FUNCTIONS--------------------------------*/
    const toggleAccordion = useCallback((accordionId) => {
        setOpenAccordion(currentAccordion => currentAccordion !== accordionId ? accordionId : 0)
    }, [])

    /*------------------------------------EFFECTS--------------------------------*/
    useImperativeHandle(ref, () => ({
        setOpenAccordion
    }), [])
    
    /*------------------------------------EFFECTS--------------------------------*/
    useEffect(() => {
        onToggle(openAccordion !== 0)
    }, [openAccordion, onToggle])

    /*------------------------------------RENDER--------------------------------*/
    className = className ? `${className} accordion_group` : 'accordion_group'

    return (
        <div className={className}>
            {accordions.map((acc, i) => (
                <AccordionGroupTab
                    key={i + 1}
                    accordionIdx={i + 1}
                    isOpen={openAccordion === (i + 1)}
                    title={acc.title}
                    toggleAccordion={toggleAccordion}
                    children={acc.content}
                    toggleElement={acc.toggleElement}
                    className={acc.className}
                />
            ))}
        </div>
    )
}

export default memo(forwardRef(AccordionGroup), reactFastCompare)