import { createContext, useCallback, useRef } from "react"
import { MessageBox, MessageConfirm } from "components"

const initialState = {
    showMB: () => { },
    showMBError: () => { },
    hideMB: () => { },
    showConfirmMsg: () => { },
    hideConfirmMsg: () => { },
}

const MessageBoxContext = createContext(initialState)

export const MessageBoxContextProvider = ({ children }) => {
    const messageBoxRef = useRef()
    const messageConfirmRef = useRef()

    const showMB = useCallback((params) => {
        messageBoxRef.current.show(params)
    }, [])

    const showMBError = useCallback((errors, ...rest) => {
        messageBoxRef.current.error(errors, ...rest)
    }, [])

    const hideMB = useCallback(() => {
        messageBoxRef.current.hide()
    }, [])

    const showConfirmMsg = useCallback((params) => {
        messageConfirmRef.current.show(params)
    }, [])

    const hideConfirmMsg = useCallback(() => {
        messageConfirmRef.current.hide()
    }, [])

    return (
        <MessageBoxContext.Provider value={{ showMB, showMBError, hideMB, showConfirmMsg, hideConfirmMsg }}>
            {children}
            <MessageBox ref={messageBoxRef} />
            <MessageConfirm ref={messageConfirmRef} />
        </MessageBoxContext.Provider>
    )
}

export default MessageBoxContext