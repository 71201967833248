
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Button, ComboBox, Grid, Text } from 'components'
import { controlStatusClass, controlStatusOptions, standardOptionsFullName } from 'scripts/generalVariables';
import { useSelector } from 'react-redux';

import ControlImplementationCard from './ControlImplementationCard';

import './control_content.scss'

const notAccordionPropertyIds = [13, 14, 22]
const implementationAccordionPropertyIds = [15, 16, 23]

const getAttributeContent = (attribute, titleSize = '3') => (
    <Grid gap="8px" key={attribute.propertyId} margin="0px 0px 10px 0px">
        <Text bold size={titleSize}>{attribute.name}</Text>
        <Text style={{ whiteSpace: 'pre-wrap' }}>{attribute.values}</Text>
    </Grid>
)

const ControlContent = () => {

    /*----------- ROUTING -----------*/
    const navigate = useNavigate()
    const { controlId } = useParams()

    /*----------- REDUX -----------*/
    const { authenticated } = useSelector(store => store.auth)

    /*----------- STATE -----------*/
    const [controlData, setControlData] = useState({ id: '', title: '', status: '', attributes: [], implementations: [] })

    /*----------- FUNCTIONS -----------*/
    const getControlData = async () => {
        try {
            const response = await axios.get(`/controls/${controlId}`, { appSpinner: true })
            if (response.data) {
                setControlData(response.data)
            }
        } catch (error) {
            console.error('GET_CONTROL_DATA_ERROR', error)
        }
    }

    const saveStatus = async (status) => {
        try {
            const response = await axios.put(`/controls/${controlId}/status`, { status }, { appSpinner: true })
            if (response.data)
                setControlData({ ...controlData, status })
        } catch (error) {
            console.error('SET_CONTROL_STATUS_ERROR', error)
        }
    }

    /*----------- EFFECTS -----------*/
    useEffect(() => {
        setTimeout(() => {
            getControlData()
        }) // setTimeout to make work appSpinner
        // eslint-disable-next-line
    }, [])

    /*----------- RENDER -----------*/
    return (
        <Grid className="cyber_security__control_content" w100 columns="auto" itemsX="center" contentY="start" gap="0.7em" padding="24px 0px">
            <Grid columns="1fr auto 1fr" w100 gap="24px">
                <Button justifySelf="start" onClick={() => { navigate('/cybersecurity') }}>Regresar</Button>
                <Text size="7" bold>{standardOptionsFullName[controlData.attributes[0]?.standardId]}</Text>
                <Grid></Grid>
            </Grid>

            <Text size="8">{controlData.title}</Text>

            <ComboBox
                w100
                maxWidth="200px"
                label="Status"
                className={`status_combobox ${authenticated ? '' : 'disabled'} ${controlStatusClass[controlData.status]}`}
                options={controlStatusOptions}
                onChange={saveStatus}
                value={controlData.status}
            />

            <Grid w100 maxWidth="700px" margin="0px 0px 24px 0px">
                <Text bold size="4">Keywords</Text>
            </Grid>

            <Grid w100 maxWidth="700px" gap="24px">
                {/* Content without accordion */}
                {
                    controlData.attributes.filter(attribute =>
                        notAccordionPropertyIds.includes(attribute.propertyId)
                    ).map(attr => getAttributeContent(attr, 4))
                }

                {/* Control attributes accordion */}
                <Accordion title="Attributes">
                    {
                        controlData.attributes.filter(attribute => (
                            !notAccordionPropertyIds.includes(attribute.propertyId) && !implementationAccordionPropertyIds.includes(attribute.propertyId)
                        )).map(attr => getAttributeContent(attr))
                    }
                </Accordion>

                {/* Control implementation guide accordion */}
                <Accordion title="Implementation Guidelines" >
                    {
                        controlData.attributes.filter(
                            attribute => implementationAccordionPropertyIds.includes(attribute.propertyId)
                        ).map(attr => getAttributeContent(attr))
                    }
                </Accordion>

                {/* Control implementation content */}
                {controlData.implementations.length > 0 &&
                    <Grid gap='1rem'>
                        {controlData.implementations.map((document, i) =>
                            <ControlImplementationCard key={i} {...{ ...document, authenticated }} />
                        )}
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default ControlContent
