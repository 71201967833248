import React, { forwardRef, memo, useMemo } from 'react'
import reactFastCompare from 'react-fast-compare'
import './flex.scss'

const Flex = (props, ref) => {
    let { className, } = props

    const {
        children, style, padding, margin, direction, gap,
        align, justify, wrap, w100, h100, maxWidth, skipClickOutside = false,
        ...rest
    } = props

    const newStyle = useMemo(() => ({
        padding,
        margin,
        flexDirection: direction,
        gap,
        alignItems: align,
        justifyContent: justify,
        flexWrap: wrap && 'wrap',
        width: w100 && '100%',
        height: h100 && '100%',
        maxWidth,
        ...style
    }), [padding, margin, direction, gap, align, justify, wrap, w100, h100, maxWidth, style])

    className = className ? `${className} flex` : 'flex'

    return (

        <div ref={ref} {...{ ...rest, className, style: newStyle, "data-skip-click-outside": skipClickOutside ? 1 : 0 }}>
            {children}
        </div >
    )
}

export default memo(forwardRef(Flex), reactFastCompare)