import React, { memo, Children } from 'react'
import reactFastCompare from 'react-fast-compare'

import { Flex, Grid } from 'components'

import './slider.scss'

function Slider(props) {

    const {
        containerProps = {},
        currentScreen = 0
    } = props

    let { className = '' } = props

    /*---------------------------------------STATE-----------------------------------------*/

    /*----------------------------------------REF------------------------------------------*/

    /*---------------------------------FUNCTIONS----------------------------------*/

    /*--------------------------------------EFFECTS----------------------------------------*/

    /*---------------------------------------RENDER----------------------------------------*/
    className += ' slider'

    return (
        <Flex className={className} {...containerProps}>
            {Children.map(props.children, (child, i) =>
                <Grid
                    className={`slider__screen${currentScreen === i ? ' open' : ''}`}
                    style={{ left: `-${currentScreen * 100}%` }}>
                    {child}
                </Grid>
            )}
        </Flex>
    )
}

// import SliderScreen from './SliderScreen'
// <SliderScreen key={i} screenIdx={i} currentScreen={currentScreen}>
//     {child}
// </SliderScreen>

export default memo(Slider, reactFastCompare)