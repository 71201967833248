
import React, { forwardRef, memo, useCallback, useImperativeHandle, useState, useRef, useReducer } from 'react'
import reactFastCompare from 'react-fast-compare'

import { Flex, Grid, Text, LottieAnimationPlayer, Button } from 'components'

import { mdAnimations } from 'scripts/generalVariables'
import './message_box.scss'

const initReducer = {
    isOpen: false,
    messageType: 'message-single',
    messageContent: '',
    messageTitle: '',
    messageAnimation: mdAnimations.ALERT,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'init':
            return initReducer
        case 'show':
            return { ...state, ...action.payload, isOpen: true }
        case 'hide':
            return { ...initReducer, isOpen: false }
        default:
            return initReducer
    }
}

const CustomMessageBox = (props, ref) => {

    let {
        className = '',
        buttons
    } = props

    const [state, dispatch] = useReducer(reducer, initReducer)

    /*---------------------------------------STATE-------------------------------------------*/
    const [buttonsRender, setButtons] = useState(buttons)
    const {
        messageContent, messageType, messageTitle, messageAnimation, isOpen
    } = state

    /*---------------------------------------REFS--------------------------------------------*/
    const messageBoxRef = useRef()

    /*---------------------------------EXTERNAL FUNCTIONS------------------------------------*/
    const show = useCallback(({
        content = '', title = 'Something went wrong', buttons = buttonsRender, animation = mdAnimations.ALERT
    } = {}) => {
        const type = content.constructor === Array ? 'message-list' : 'message-single'

        dispatch({
            type: 'show', payload: {
                messageContent: content,
                messageType: type,
                messageTitle: title,
                messageAnimation: mdAnimations[animation] || mdAnimations.ALERT,
            }
        })
        setButtons(buttons)
    }, [dispatch, buttonsRender])

    const hide = useCallback(() => {
        dispatch({ type: 'hide' })
    }, [dispatch])

    const error = useCallback((errors = [], animation = 'ERROR', title = 'Something went wrong') => {
        show({
            type: errors.constructor === Array ? 'message-list' : 'message-single',
            title,
            content: errors,
            animation,
            buttons: <Button onClick={hide}>Got it</Button>
        })
    }, [hide, show])

    useImperativeHandle(ref, () => ({
        show,
        hide,
        error,
    }), [error, hide, show])

    /*--------------------------------------RENDER-------------------------------------------*/
    className = className ? `message_box ${className}` : 'message_box'

    const containerProps = { className: 'message_box__content', gap: '1em' }

    if (!isOpen) return null
    else {
        let contentRender
        switch (messageType) {
            case 'message-single':
                contentRender = messageContent && (
                    <Grid {...containerProps}>
                        {messageContent.constructor === String ?
                            <Text align="center">{messageContent}</Text>
                            :
                            messageContent
                        }
                    </Grid>
                )
                break
            case 'message-list':
                contentRender = messageContent && (
                    <Grid {...containerProps}>
                        <ul>
                            {messageContent.map((c, i) => <li key={i}>{c}</li>)}
                        </ul>
                    </Grid>
                )
                break
            default: contentRender = null
        }

        return (
            <Flex w100 h100 skipClickOutside align='center' justify='center' className='message_box_modal' ref={messageBoxRef}>
                <Grid w100 className={className} padding='2em' gap='1em'>
                    <Grid>
                        <LottieAnimationPlayer className="message_box__animation" autoplay loop animation={messageAnimation} />
                    </Grid>
                    {messageTitle !== '' && <Text bold className='message_box__title'>{messageTitle}</Text>}
                    {contentRender}
                    <Flex wrap className='message_box__buttons' justify='center'>
                        {buttonsRender}
                    </Flex>
                </Grid>
            </Flex>
        )
    }
}
export default memo(forwardRef(CustomMessageBox), reactFastCompare)