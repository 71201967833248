import { createContext, useCallback, useState } from "react"

import { Spinner } from "components"

const initialState = {
    showSpinner: () => { },
    hideSpinner: () => { },
}

const AppSpinnerContext = createContext(initialState)

export const AppSpinnerContextProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false)
    const setLoadingCount = useState(0)[1]

    const showSpinner = useCallback(() => {
        setIsLoading(true)
        setLoadingCount(loadingCount => ++loadingCount)
    }, [setLoadingCount])

    const hideSpinner = useCallback(() => {
        setLoadingCount(loadingCount => {
            if (loadingCount === 1) setTimeout(() => setIsLoading(false), 300)
            return --loadingCount
        })
    }, [setLoadingCount])

    return (
        <AppSpinnerContext.Provider value={{ showSpinner, hideSpinner }}>
            {children}
            <Spinner appSpinner isLoading={isLoading} />
        </AppSpinnerContext.Provider>
    )
}

export default AppSpinnerContext
