
import React, { useCallback, useRef } from 'react'
import axios from 'axios'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Grid, Text } from 'components'
import { routes } from 'routing/routes'
import { useSelector, useDispatch } from 'react-redux'
import { logout, setUser } from 'redux/reducers/auth/authSlice'

import './profile.scss'

const DEFAULT_PROFILE_PHOTO = "https://magiei-resources.s3.us-east-2.amazonaws.com/Icons/icon-user-edit.svg"

const Profile = () => {
    const fileReference = useRef()

    const { auth } = useSelector(store => store)
    const dispatch = useDispatch()

    const logOut = () => {
        try {
            dispatch(logout())
        } catch (error) {
            console.log(error)
        }
        // localStorage.removeItem('user')
        // localStorage.removeItem('token')
        // localStorage.removeItem('md_v_u_s')
        // localStorage.removeItem('md_ac_u_s')
        // localStorage.clear()
        // document.location.reload()
    }

    const changeUserPhoto = useCallback((event) => {
        console.log("ALAN", event.target.files[0])
        axios.post('/user/upload', {
            formData: { file: event.target.files[0] },
        }).then(response => {
            fileReference.current.value = ""
            const user = JSON.parse(localStorage.getItem('user'))
            user.photoUrl = response.data.photoUrl
            localStorage.setItem('user', JSON.stringify(user))
            dispatch(setUser({ photoUrl: response.data.photoUrl }))
        }).catch(err => {
            console.log(err)
        })
    }, [dispatch])

    const imageClassName = (auth.user.photoUrl === DEFAULT_PROFILE_PHOTO || auth.user.photoUrl === "") ? "user_info__default" : "account__user_photo"

    return (
        <Grid className="account" itemsX="center" contentY="start" gap="0.7em" padding="1.14em 0.42em">
            <Grid w100 gap="1.71em" contentY="start" itemsX="center" padding="1.71em 4.57em" className="account__user_info">
                <Grid itemsX="center" gap="0.7em">
                    <input
                        className='account__user_input_photo'
                        type="file"
                        ref={fileReference}
                        name="myImage"
                        onChange={changeUserPhoto}
                    />
                    <img alt="" src={auth.user.photoUrl || DEFAULT_PROFILE_PHOTO} className={imageClassName} />
                    <Text medium>{auth.user.name}</Text>
                    <Text medium>{auth.user.email}</Text>
                </Grid>
                <RouterLink to={routes.changePassword.path} >
                    <Button>Cambiar contraseña</Button>
                </RouterLink>
            </Grid>
            <Button style={{ marginTop: '0.52em' }} onClick={logOut} className="account__logout">Cerrar sesión</Button>
        </Grid>
    )
}

export default Profile
