export const screenSizes = {
    'mobile': 0,
    'tablet': 480,
    'laptop': 768,
    'desktop': 1024,
    'tv': 1440,
    '4k': 2500,
}

export const notValidTokenCodes = [
    'MDT_APP_TOKEN_NOT_VALID',
    'FST_JWT_AUTHORIZATION_TOKEN_INVALID',
    'FST_JWT_AUTHORIZATION_TOKEN_EXPIRED',
    'FST_JWT_NO_AUTHORIZATION_IN_HEADER'
]

export const mdAnimations = {
    SUCCESS: 'MD-Exito',
    ERROR: 'MD-Error',
    ALERT: 'MD-Alerta',
    INFO: 'MD-Informativo'
}

export const standardOptions = {
    1: 'ISO 27001',
    4: 'CSA STAR',
    5: 'InteliGene',
}

export const standardOptionsFullName = {
    1: 'ISO/IEC 27001:2022',
    2: 'ISO/IEC 27001:2022',
    4: 'CSA STAR',
    5: 'InteliGene',
}

export const controlStatusOptions = {
    NON_APPLICABLE: 'Not-applicable',
    PENDING: 'Pending',
    DRAFT: 'Draft',
    NON_COMPLAINT: 'Non-compliant',
    COMPLAINT: 'Compliant',
    ENFORCED_REVIEW: 'Enforced Review',
    UNDER_REVIEW: 'Under Review',
    ACTIVE: 'Active'
}

export const controlStatusClass = {
    NON_APPLICABLE: 'non_applicable',
    PENDING: 'pending',
    DRAFT: 'draft',
    NON_COMPLAINT: 'non_complaint',
    COMPLAINT: 'complaint',
    ENFORCED_REVIEW: 'enforced_review',
    UNDER_REVIEW: 'under_review',
    ACTIVE: 'active',
}

export const abbMonths = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
}

export const fullMonths = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
}