
import React, {memo, forwardRef, useImperativeHandle, useRef, useState, useEffect,} from 'react'

import {Player, Controls} from '@lottiefiles/react-lottie-player'

import { animationS3 } from 'scripts/assetsS3'
import { animationConfig } from 'scripts/animationConfig'

const dfun = () => {}

export default memo ( forwardRef( function LottieAnimationPlayer (props, ref) {
    
    let {
        className = '',
        onClick = dfun,
        onMouseEnter = dfun,
        onMouseLeave = dfun,
        animation = '', 
        loop = false,
        autoplay = false,
        controls = false,
        reset = false,
        controlButtons = [], //'play', 'repeat', 'frame', 'debug'
        style = {}, 
        
        
        splitAnimation = false,
        splitInFrames = false,
        animationArray = [],
        speed = 1,
        defaultSegment = 1,
        
        onComplete = dfun,
    } = {...props, ...animationConfig[props['animation']] }
    
    if(props.splitAnimation !== undefined) splitAnimation = props.splitAnimation
    if(props.splitInFrames !== undefined) splitInFrames = props.splitInFrames
    if(props.animationArray !== undefined) animationArray = props.animationArray
    if(props.speed !== undefined) speed = props.speed
    
    /*-------------------------------------------------REFS-------------------------------------------------*/
        const animationContainer = useRef()
        
    /*-------------------------------------------------STATE-------------------------------------------------*/
        
        const [lottie, setLottie] = useState() //HAS ANIMATION LIBRARY REF
        const [actualFrame, setActualFrame] = useState([0, 0]) //HAS THE ACTUAL FRAME TO AVOID RESTART WHEN IT FINISHES
        const [totalFrames, setTotalFrames] = useState(0) //HAS TOTAL FRAMES OF ANIMATION
        const [animationArrayInFrames, setAnimationArrayInFrames] = useState([]) //THE ARRAY WITH THE SEGMENTS VALUES
    
    /*-----------------------------------------------FUNCTIONS-------------------------------------------------*/
        
        //CONVERT ARRAY PORCENTAGES TO FRAMES OF ANIMATION
            const convertAnimationArrayPorcentagesToFrames = () => {
                //CHECK IF ARRAY IS ALREADY IN FRAMES
                    if(splitInFrames) setAnimationArrayInFrames(animationArray)
                    else {
                        let animationArrayTemp = [...animationArray]
                        
                        //GET FRAMES OF EACH SEGMENT
                            
                        
                            animationArrayTemp.forEach((range, i) => {
                                //RANGE IN 100 [0, 45%]
                                const segmentFramesStart = totalFrames * range[0] / 100
                                const segmentFramesEnd = totalFrames * range[1] / 100
                                animationArrayTemp[i] = [Math.floor(segmentFramesStart), Math.floor(segmentFramesEnd)]
                            })
                            
                        setAnimationArrayInFrames(animationArrayTemp)
                    }
            } 
        
        //PLAY SEGMENT OF ANIMATION
            const playSegment = (segment) => {
                segment--
                //GET START AND END OF SEGMENT
                    const range = animationArrayInFrames[segment]
                    const framesAtStart = range[0]
                    const framesAtEnd = range[1]
                //PLAY A SEGMENT OF ANIMATION
                    setActualFrame([framesAtStart, framesAtEnd])
                    lottie.playSegments([[framesAtStart, framesAtEnd]], true) 
            }
        
        //STOP ON ACTUAL FRAME AVOIDING RESTART ANIMATION
            const stopFrame = () => { if(!reset) lottie.goToAndStop(actualFrame[1] - actualFrame[0], true);}
    
    /*--------------------------------------------EXTERNAL FUNCTIONS-------------------------------------------*/
       
        const play = (segment = 1) => {
            if(lottie){
                if(splitAnimation) playSegment(segment)
                else lottie.play()
            }
        }
        
        

        const goToSegment = (segment = 1) => {
            if(lottie){
                if(splitAnimation){
                    segment--
                    const range = animationArrayInFrames[segment]
                    lottie.goToAndStop(range[0], true)
                }
            }
        }
        
        useImperativeHandle(ref, () => ({ play, goToSegment }))
        
    /*--------------------------------------------------EVENTS-------------------------------------------------*/
        
        //ON ANIMATION IS LOADED
            useEffect(() => {
                if(lottie){
                    setTotalFrames(lottie.totalFrames)
                    lottie.setSubframe(false)
                    lottie.setSpeed(speed)
                    setActualFrame(actualFrame)
                }
            // eslint-disable-next-line    
            }, [lottie])
        
        //LOAD TOTAL FRAMES WHEN ANIMATION IS LOADED
            useEffect(() => {
                if(lottie){
                    convertAnimationArrayPorcentagesToFrames()
                }
            // eslint-disable-next-line
            }, [totalFrames])
        
        //LOAD ON COMPLETE FUNCTION WHEN ACTUAL FRAME IS CHANGED
            useEffect(() => {
                if(lottie){
                    lottie.onComplete = () => {
                        stopFrame()
                        onComplete()
                    }
                }
                // eslint-disable-next-line
            }, [actualFrame])
            
        //LOAD DEFAULT SEGMENT WHEN ANIMATION ARRAY IS LOADED
            useEffect(() => {
                goToSegment(defaultSegment)
                // eslint-disable-next-line
            }, [animationArrayInFrames])
            
    /*---------------------------------------------------RENDER-------------------------------------------------*/
        
        className += ` animation animation--${animation}`
        
        let stylePlayer = {
            height: '100%',
            width: '100%',
            display: 'flex'
        }
        
        return <span className={className} ref={animationContainer} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} style={style} >
            <Player {...{ 
                src: animationS3(animation),
                loop, 
                autoplay, 
                style: stylePlayer,
                lottieRef: setLottie
            } } >
                <Controls visible={controls} buttons={controlButtons} />
            </Player>
        </span>
}))