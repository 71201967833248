import axios from "axios"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useNavigate } from "react-router"

import { useAppSpinnerContext, useMessageBoxContext, useAppProcessingContext } from "hooks"

import { logout } from "redux/reducers/auth/authSlice"

import { routes } from "routing/routes"

import { Button } from "components"

import { notValidTokenCodes } from "scripts/generalVariables"

const AxiosProvider = () => {
    const { authenticated } = useSelector(store => store.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showMB, hideMB, showMBError } = useMessageBoxContext()
    const { showProcess, hideProcess, stopProcess, errorProcess } = useAppProcessingContext()
    const { showSpinner, hideSpinner } = useAppSpinnerContext()

    useEffect(() => {
        const reqInterceptor = (config) => {
            if (config.appProcessing) showProcess()
            if (config.appSpinner) showSpinner()
            return config
        }

        const reqErrInterceptor = error => {
            if (error.config.appProcessing) errorProcess()
            if (error.config.appSpinner) hideSpinner()

            return Promise.reject(error)
        }

        const resInterceptor = response => {
            if (response.config.appProcessing) response.config.saveProcessing ? hideProcess() : stopProcess()
            if (response.config.appSpinner) hideSpinner()
            return response
        }

        const resErrInterceptor = error => {
            if (error.config.appProcessing) errorProcess()
            if (error.config.appSpinner) hideSpinner()
            if (error.response?.data) {
                if (error.response.data.code === 'CODE_STILL_NOT_IDENTIFIED') {
                    showMBError(error.response.data.errors || 'DEV ERROR')
                } else if (notValidTokenCodes.includes(error.response.data.code)) {
                    if (authenticated)
                        dispatch(logout()).then(() => {
                            navigate(routes.login.path)
                        })
                    showMB({
                        title: 'La sesión expiró',
                        content: 'Por favor inicia sesión de nuevo',
                        buttons: <Button onClick={hideMB}>Got it</Button>
                    })
                    return Promise.reject(error)
                } else if (error.config.catchErrors && error.response.data.errors) {
                    showMBError(error.response.data.errors)
                }
            } else if (error.config.catchNetworkError !== false) {
                showMBError()
            }

            return Promise.reject(error)
        }

        const requestInterceptor = axios.interceptors.request.use(reqInterceptor, reqErrInterceptor)
        const responseInterceptor = axios.interceptors.response.use(resInterceptor, resErrInterceptor)

        return () => {
            axios.interceptors.request.eject(requestInterceptor)
            axios.interceptors.response.eject(responseInterceptor)
        }

        // eslint-disable-next-line
    }, [authenticated])

    return <Outlet />
}

export default AxiosProvider