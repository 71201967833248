import React, { memo, forwardRef, useCallback, useRef, useState, useImperativeHandle } from 'react'

import { Grid, LottieAnimationPlayer } from 'components'

import './processing.scss'

const Processing = (props, ref) => {

    const { initLoading = false } = props

    const [isLoading, setIsLoading] = useState(initLoading)

    const animationRef = useRef()
    const currentProcesses = useRef(0)

    const showProcess = useCallback(() => {
        currentProcesses.current = currentProcesses.current + 1
        animationRef.current.play(1)
        setIsLoading(true)
    }, [])

    const hideProcess = useCallback(() => {
        animationRef.current.play(2)
        currentProcesses.current = currentProcesses.current > 0 ? currentProcesses.current - 1 : 0
        setTimeout(() => { if (currentProcesses.current === 0) setIsLoading(false) }, 1000)
    }, [])

    const stopProcess = useCallback(() => {
        currentProcesses.current = currentProcesses.current > 0 ? currentProcesses.current - 1 : 0
        setTimeout(() => { if (currentProcesses.current === 0) setIsLoading(false) }, 1000)
    }, [])

    const errorProcess = useCallback(() => {
        setTimeout(() => setIsLoading(false), 500)
        currentProcesses.current = 0
    }, [])

    useImperativeHandle(ref, () => ({
        showProcess,
        hideProcess,
        stopProcess,
        errorProcess
    }), [showProcess, hideProcess, stopProcess, errorProcess])

    return (
        <Grid skipClickOutside className={`processing_container${isLoading ? ' is_loading' : ''}`}>
            <LottieAnimationPlayer splitAnimation splitInFrames loop
                ref={animationRef}
                animation='Cargando-guardado'
                animationArray={[[0, 161], [162, 225]]}
            />
        </Grid>
    )
}

export default memo(forwardRef(Processing))