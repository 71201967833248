
import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { useNavigate, useOutletContext } from 'react-router-dom'
import { Grid, Text, FilterTable } from 'components'

import { controlStatusClass, controlStatusOptions, standardOptionsFullName } from 'scripts/generalVariables'
import './control_list.scss'

const ControlList = (props) => {

    const [standardAttribute] = useOutletContext()
    const [rows, setRows] = useState([])
    const navigate = useNavigate()

    const getRows = async (searchingName = '') => {
        try {
            const endpointParams = {
                attributeValueId: parseInt(standardAttribute.attribute) === 0 ? standardAttribute.controlId : standardAttribute.controlName,
                attributeId: standardAttribute.attribute,
                standardId: standardAttribute.standard,
                searchingName
            }
            const response = await axios.get(`/controls${global.mapObjectToHttpParams(endpointParams)}`)
            if (response.data) {
                setRows(response.data)
            }
        } catch (error) {
            console.error('GET_CONTROLS_ERROR', error)
        }
    }

    useEffect(() => {
        getRows()
        // eslint-disable-next-line
    }, [standardAttribute.controlId])

    return (
        <Grid w100 className="cyber_security__control_list" itemsX="center" contentY="start" gap="24px" padding="1.14em 0.42em">
            <Text size="8">{standardOptionsFullName[standardAttribute.standard]}</Text>
            <Grid w100 maxWidth="1000px" gap="16px">
                <Text size="3" bold>{standardAttribute.controlName}</Text>
                <FilterTable
                    columns={[
                        { displayName: 'Control', name: 'title' },
                        { displayName: 'Status', name: 'status', format: v => controlStatusOptions[v], classFunction: v => controlStatusClass[v] },
                        { displayName: 'Last Modified', name: 'last_modified', format: global.formatDateAbbMonth }
                    ]}
                    onChangeFilter={(v) => { getRows(v) }}
                    rows={rows}
                    onClickRow={(row) => { navigate(`control_content/${row.id}`) }}
                    templateColumns='1fr 12rem 8rem'
                />
            </Grid>
        </Grid>
    )
}

export default ControlList
