import {
    ChangePassword, Home, Login, Profile, RecoverPassword, RecoverPasswordForm,
    ViteCounter, CyberSecurity, ControlContent, ControlList, //Signup
} from "Pages"
import { Route } from "react-router-dom"

/* (If apply)
 * headerRoutes: string[] ->    links that will be displayed in header
 * sideMenuRoutes: string[] ->  links that will be displayed in side menu
 */
export const headerRoutes = []
export const sideMenuRoutes = []

/* routes access types
 * publicRoutes: string[]   ->      does not matter if a user session exists
 * requireNoAuthRoutes: string[] -> routes require a user session does not exist
 * requireAuthRoutes: string[] ->   routes require a user session exists
 */
export const publicRoutes = ['vite_counter', 'cybersecurity']
export const requireNoAuthRoutes = ['login', /*'signup',*/ 'recoverPassword', 'recoverPasswordForm']
export const requireAuthRoutes = ['home', 'profile', 'changePassword']

/**
 * Routes object
 * path -> text displayed in url
 * linkName -> text to display in app links (header, sidemenu)
 */

export const childRoutes = {
    cybersecurityControlList: {
        path: '',
        // linkName: 'Home',
        // element: <Home />
    },
    cybersecurityControlContent: {
        path: 'control_content/:controlId',
        // linkName: 'Home',
        // element: <Home />
    },
}

export const routes = {
    home: {
        path: '/',
        linkName: 'Home',
        element: <Home />
    },
    login: {
        path: '/login',
        linkName: 'Login',
        element: <Login />
    },
    // signup: {
    //     path: '/register',
    //     linkName: 'Signup',
    //     element: <Signup />
    // },
    recoverPassword: {
        path: '/recover_password',
        linkName: 'Recover password',
        element: <RecoverPassword />
    },
    recoverPasswordForm: {
        path: '/recover_password/:tokenPassword',
        linkName: 'Recover password',
        element: <RecoverPasswordForm />
    },
    changePassword: {
        path: '/change_password',
        linkName: 'Change password',
        element: <ChangePassword />
    },
    profile: {
        path: '/profile',
        linkName: 'Profile',
        element: <Profile />
    },
    cybersecurity: {
        path: '/cybersecurity',
        linkName: 'cybersecurity',
        element: <Route key='cybersecurity' element={<CyberSecurity />} path='/cybersecurity'>
            <Route element={<ControlList />} path={childRoutes.cybersecurityControlList.path}></Route>
            <Route element={<ControlContent />} path={childRoutes.cybersecurityControlContent.path}></Route>
        </Route>,
        isChildRoute: true
    },
    vite_counter: {
        path: '/vite_counter',
        linkName: 'Vite Counter',
        element: <ViteCounter />
    },
}

/**
 * Not render header on this routes
 */
export const noHeaderRoutes = [routes.vite_counter.path, ...requireNoAuthRoutes.map(route => routes[route].path)]

/**
 * routes that are not applied to redirect after sign in
 */
export const noRedirectPaths = [routes.changePassword.path, routes.profile.path]
