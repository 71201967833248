import React from 'react'
import { Grid, Link, Text } from 'components'
import { routes } from 'routing/routes'

const Home = () => {
    return (
        <Grid contentY="start" itemsX="center" padding="2em">
            <Text size={7}>InteliGene</Text>
            <Grid rows='200px' columns='repeat(3, 1fr)'>
                <Grid style={{ radius: '2px solid var(--color--gray--3)', borderRadius: '1rem' }} padding='1rem' contentY='center'>
                    <Link router medium size='7' align="center" href={routes.cybersecurity.path}>Cybersecurity</Link>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default Home
