
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Grid, } from 'components'

import './cyber_security.scss'

import SideMenu from './SideMenu/SideMenu'

const CyberSecurity = () => {

    const [standardAttribute, setStandardAttribute] = useState({ standard: 1, attribute: 0, controlName: 'Organizational controls', controlId: 8 })

    return (
        <Grid className="cyber_security" columns="auto 1fr" itemsX="center" contentY="start" gap="24px" rows="100%">
            <SideMenu standardAttribute={standardAttribute} setStandardAttribute={setStandardAttribute} />
            <Outlet context={[standardAttribute]} />
            {/* <ControlList standardAttribute={standardAttribute} /> */}
        </Grid>
    )
}

export default CyberSecurity
