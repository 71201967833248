export const animationConfig = {
    toggle: {
        splitAnimation: true,
        splitInFrames: false,
        animationArray: [[20, 50], [75, 100]],
        speed: 4,
    },
    checkbox: {
        splitAnimation: true,
        splitInFrames: false, 
        animationArray: [[0, 50], [50, 100]],
        speed: 6,
    },
    burger_button: {
        splitAnimation: true,
        splitInFrames: false,
        animationArray: [[0, 50], [50, 100]],
        speed: 4,
    },
    Hamburguesa: {
        splitAnimation: true,
        splitInFrames: false,
        animationArray: [[0, 30], [25, 100]],
        speed: 2,
    },
    Arrow: {
        splitAnimation: true,
	    splitInFrames: true,
	    animationArray: [[0,1], [0, 29], [30, 31], [32, 60]],
    },
    MaGiEiPregunta: {
        autoplay: true,
        loop: true,
    }
}