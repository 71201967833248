import React, { memo } from 'react'
import reactFastCompare from 'react-fast-compare'

import './button.scss'

const tp = (propName, prop) => {
    if (prop !== '' && prop !== undefined) return ` button--${propName}--${prop}`
    else return ''
}

const Button = props => {
    let {
        className = '',
        width = '',
        maxWidth = '',
        alignSelf = '',
        justifySelf = '',
        selfCenter = false,
        style = {},
        variant = 'filled',
        color = '',
        ...rest
    } = props

    if (selfCenter) { justifySelf = 'center'; alignSelf = 'center' }
    style = {
        ...style,
        width,
        maxWidth,
        alignSelf,
        justifySelf
    }
    className += tp('variant', variant)
    className += tp('color', color)
    className += ' button'

    return <button {...{ className, style, ...rest }}>{props.children}</button>

}

export default memo(Button, reactFastCompare)
