import { useMessageBoxContext } from 'hooks'
import axios from 'axios'

import { Button, Grid, Text } from 'components'

import './control_content.scss'

const ControlImplementationCard = (props) => {
    const { showMBError } = useMessageBoxContext()
    const getImplementationUrl = async () => {

        if (!props.authenticated && props.restriction === 2) {
            showMBError('You must signin to view documentation')
            return
        }

        try {
            const response = await axios.get(`/documents/${props.urlId}/info`, { catchErrors: true, appSpinner: true })
            if (response.data)
                window.open(response.data.url)
        } catch (error) {
            console.error('GET_IMPLEMENTATION_URL_ERROR', error)
        }
    }
    return (
        <Grid className='control_implementation_card'>
            <Text>{props.summary}</Text>
            <Button onClick={getImplementationUrl}>View documentation</Button>
        </Grid>
    )
}

export default ControlImplementationCard